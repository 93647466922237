<template>
  <div class="template_wrap">
    <COMMONTOPSEARCH ref="commonTopSearchRef"
                     :showSearch="false"
                     :isDefault="false"
                     url="/api/v1/public/test_subjects"
                     @initData="getDirList" />
    <div class="list_wrap">
      <div class="dir_list">
        <div class="dir_item"
             v-for="item,index in dirList"
             :key="item.paper_district_id">
          <div :class="'item_text item_text_'+index%8">
            <div class="subject_card">{{subjectName}}</div>
            <div class="dir_desc">{{item.paper_district_name}}</div>

          </div>
          <a-button @click="doPaper(item)"
                    class="btn">开始测试</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import { getTemplateDir } from '@/api/paper.js'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const dirList = ref([])
const subjectId = ref('')
const subjectName = ref('')
let router = useRouter()
const getDirList = async (id, keyword, name) => {
  subjectName.value = name
  const { data } = await getTemplateDir({
    'subject_id': id
  })
  dirList.value = data
  subjectId.value = id
}
// 点击继续做卷
const doPaper = (item) => {
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  let form = {
    paper_district_id: item.paper_district_id,
    subject_id: subjectId.value
  }
  router.push('/doPaper?isTemplate=1' + '&form=' + JSON.stringify(form))
}
</script>

<style lang="scss" scoped>
.template_wrap {
  width: 100%;
  height: 100vh;
  background: url("../../static/allBackg/bg-组卷测试.png") 0/100% 100% no-repeat;
  .list_wrap {
    margin: 40rem 6.25%;
    background: #ffffff;
    border-radius: 28rem;
    height: calc(100vh - 288rem);
    padding: 32rem 0 32rem 32rem;
    overflow: hidden;
  }
  .dir_list {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;
    // justify-content: space-between;
    .dir_item {
      margin: 0 32rem 32rem 0;
      // width: 298rem;
      width: calc(20% - 32rem);
      min-width: 114px;
      height: 240rem;
      border-radius: 16rem;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }
    .item_text {
      width: 298rem;
      max-width: 100%;
      height: 176rem;
      background: linear-gradient(134deg, #7ed8fe 0%, #40b1f5 100%);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      color: #ffffff;
      .dir_desc {
        font-size: 24rem;
        text-align: center;
        font-weight: 500;
        line-height: 36rem;
        padding: 24rem 70rem 70rem;
      }
      .subject_card {
        font-size: 20rem;
        font-weight: 400;
        line-height: 30rem;
        padding: 8rem 24rem;
        text-align: left;
        display: inline-block;
        border-bottom-right-radius: 16rem;
        background: #01c8e9;
      }
    }

    .item_text_0 {
      background: linear-gradient(134deg, #7ed8fe 0%, #40b1f5 100%);
      .subject_card {
        background: #40b1f5;
      }
    }

    .item_text_1 {
      background: linear-gradient(134deg, #ff9aa4 1%, #ff6e6e 97%);
      .subject_card {
        background: #ff6f6f;
      }
    }

    .item_text_2 {
      background: linear-gradient(134deg, #1edbca 0%, #0bbfb0 100%);
      .subject_card {
        background: #0bc0b1;
      }
    }

    .item_text_3 {
      background: linear-gradient(134deg, #9fbefa 0%, #5797ed 100%);
      .subject_card {
        background: #5998ed;
      }
    }

    .item_text_4 {
      background: linear-gradient(134deg, #ffd99a 0%, #ffc36e 100%);
      .subject_card {
        background: #ffc36f;
      }
    }

    .item_text_5 {
      background: linear-gradient(134deg, #6ee7f0 0%, #01c8e9 100%);
      .subject_card {
        background: #02c9e9;
      }
    }

    .item_text_6 {
      background: linear-gradient(134deg, #ffd7ce 0%, #f8b7a8 100%);
      .subject_card {
        background: #f8b7a8;
      }
    }

    .item_text_7 {
      background: linear-gradient(134deg, #dacbf9 0%, #b89ef1 100%);
      .subject_card {
        background: #b89ef1;
      }
    }
  }
  .btn {
    width: 100%;
    height: auto;
    padding: 17rem 0;
    font-size: 20rem;
    font-weight: 400;
    color: #333333;
    text-align: center;
    border: 0;
    line-height: 30rem;
  }
}
</style>